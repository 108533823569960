define("discourse/plugins/alternativet-sso/discourse/authentication-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: 'root',
    map() {
      this.route('authentication', {
        path: '/authentication/:provider_name'
      });
    }
  };
});