define("discourse/plugins/alternativet-sso/discourse/routes/authentication", ["exports", "discourse/models/login-method"], function (_exports, _loginMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function (params) {
      let loginController = this.controllerFor('login');
      let isMobileDevice = this.site.get('isMobileDevice');
      let capabilities = this.container.lookup('capabilities:main');
      let loginMethods = (0, _loginMethod.findAll)(this.siteSettings, capabilities, isMobileDevice);
      let loginMethod = loginMethods.findBy('name', params.provider_name);
      loginController.send('externalLogin', loginMethod);
    }
  });
});